import React from 'react';
import Column from '../components/Column';
import Grid from '../components/Grid';
import ImageTeaserBotox from '../components/images/ImageTeaserBotox';
import ImageTeaserContouring from '../components/images/ImageTeaserContouring';
import ImageTeaserFadenlifting from '../components/images/ImageTeaserFadenlifting';
import ImageTeaserFiller from '../components/images/ImageTeaserFiller';
import ImageTeaserMesotherapie from '../components/images/ImageTeaserMesotherapie';
import ImageTeaserMicroNeedling from '../components/images/ImageTeaserMicroNeedling';
import ImageTeaserPeeling from '../components/images/ImageTeaserPeeling';
import ImageTeaserPRP from '../components/images/ImageTeaserPRP';
import ImageTeaserVolumeFiller from '../components/images/ImageTeaserVolumeFiller';
import Layout from '../components/layout';
import Questions from '../components/Questions';
import Teaser from '../components/teaser';

const AesthetischeDermatologie = ({ data, location, ...props }) => {
	return (
		<Layout
			location={location}
			title="Ästhetische Dermatologie"
			noSlider
			{...props}
		>
			<Questions title="Ästhetische Dermatologie" />
			<div className="mt-12" />
			<Grid className="pt-4">
				<Column md="1/2">
					<Teaser
						to="/aesthetische-dermatologie/behandlung-mimischer-faeltchen-mit-botox"
						title="Botox"
						image={<ImageTeaserBotox />}
					/>
				</Column>
				<Column md="1/2">
					<Teaser
						to="/aesthetische-dermatologie/mesotherapie"
						title="Mesotherapie"
						image={<ImageTeaserMesotherapie />}
					/>
				</Column>
				<Column md="1/2" className="mt-6">
					<Teaser
						to="/aesthetische-dermatologie/behandlung-statischer-faeltchen-mit-fillern"
						title="Filler"
						image={<ImageTeaserFiller />}
					/>
				</Column>
				<Column md="1/2" className="mt-6">
					<Teaser
						to="/aesthetische-dermatologie/volumenfiller"
						title="Volumenfiller"
						image={<ImageTeaserVolumeFiller />}
					/>
				</Column>
				<Column md="1/2" className="mt-6">
					<Teaser
						to="/aesthetische-dermatologie/koerper-contouring-mit-injektionslipolyse"
						title="Contouring"
						image={<ImageTeaserContouring />}
					/>
				</Column>
				<Column md="1/2" className="mt-6">
					<Teaser
						to="/aesthetische-dermatologie/fadenlifting"
						title="Fadenlifting"
						image={<ImageTeaserFadenlifting />}
					/>
				</Column>
				<Column md="1/2" className="mt-6">
					<Teaser
						to="/aesthetische-dermatologie/vampir-lift-prp-therapie"
						title="Vampir Lift / RPR"
						image={<ImageTeaserPRP />}
					/>
				</Column>
				<Column md="1/2" className="mt-6">
					<Teaser
						to="/aesthetische-dermatologie/micro-needling"
						title="Micro-Needling"
						image={<ImageTeaserMicroNeedling />}
					/>
				</Column>
				<Column md="1/2" className="mt-6">
					<Teaser
						to="/aesthetische-dermatologie/medizinisches-peeling"
						title="Peeling"
						image={<ImageTeaserPeeling />}
					/>
				</Column>
			</Grid>
		</Layout>
	);
};

export default AesthetischeDermatologie;
